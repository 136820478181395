import { useTranslation } from "next-i18next";
import clsx from "clsx";

import Link from "@/components/hoc/CustomLink";

function RegisterCTAs() {
  const { t } = useTranslation("common");

  return (
    <div
      className={clsx(
        "flex flex-col lg:flex-row items-center lg:items-stretch",
        "lg:justify-center py-10 lg:py-[3.8vw]"
      )}
    >
      <div
        className={clsx(
          "w-[80vw] lg:w-[42vw] max-w-[600px] p-6 lg:p-12 flex flex-col",
          "shadow-small lg:hover:shadow-large rounded-lg mb-8 lg:mb-0 lg:mr-[3.8vw] relative",
          "before:border-gradient before:border before:bg-dark-stroke before:rounded-lg",
          "bg-gradient-to-bl from-green-2/20 hover:from-green-2/60"
        )}
      >
        <span className="text-28 leading-1 lg:text-3xl lg:leading-0.95 font-medium">
          {t("common.footer.cta.label.title")}
        </span>
        <span
          className={clsx("text-base leading-1.12 lg:text-lg lg:leading-1.2 mt-3", "lg:mt-4 mb-6 lg:mb-8")}
        >
          {t("common.footer.cta.label.subtitle")}
        </span>
        <a
          href="https://form.typeform.com/to/YDgM8tOr"
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(
            "w-max h-10 lg:h-12 px-5 lg:px-7 rounded-full bg-gradient-selected",
            "flex items-center text-white text-base leading-1 lg:text-lg",
            "lg:leading-1 font-medium lg:hover:shadow-button group-unique"
          )}
        >
          {t("common.footer.cta.label.button")}
          <span
            className={clsx("ml-1.5 lg:ml-2 lg:hidden lg:group-unique-hover:block", "animate-right-bounce")}
          >
            →
          </span>
        </a>
      </div>

      <div
        className={clsx(
          "w-[80vw] lg:w-[42vw] max-w-[600px] p-6 lg:p-12 flex flex-col",
          "shadow-small lg:hover:shadow-large rounded-lg relative",
          "before:border-gradient before:border before:bg-dark-stroke before:rounded-lg",
          "bg-gradient-to-bl from-purple/20 hover:from-purple/50"
        )}
      >
        <span className="text-28 leading-1 lg:text-3xl lg:leading-0.95 font-medium">
          {t("common.footer.cta.artist.title")}
        </span>
        <span
          className={clsx("text-base leading-1.12 lg:text-lg lg:leading-1.2 mt-3", "lg:mt-4 mb-6 lg:mb-8")}
        >
          {t("common.footer.cta.artist.subtitle")}
        </span>
        <Link href="/artists/register">
          <a
            className={clsx(
              "w-max h-10 lg:h-12 px-5 lg:px-7 rounded-full bg-gradient-selected",
              "flex items-center text-white text-base leading-1 lg:text-lg",
              "lg:leading-1 font-medium lg:hover:shadow-button group-unique"
            )}
          >
            {t("common.footer.cta.artist.button")}
            <span
              className={clsx("ml-1.5 lg:ml-2 lg:hidden lg:group-unique-hover:block", "animate-right-bounce")}
            >
              →
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
}

export default RegisterCTAs;
