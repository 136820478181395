import { PropsWithChildren } from "react";
import useHasMounted from "@/utils/hooks/useHasMounted";

/**
 * Renders children only once the component
 * has mounted (and therefore only client side).
 *
 * @param props - Component props
 *
 * @remarks Simply uses the `useHasMounted` hook.
 *
 * @returns children
 */
export default function ClientOnly({ children }: PropsWithChildren<unknown>) {
  const hasMounted = useHasMounted();

  if (!hasMounted) return null;

  return <>{children}</>;
}
