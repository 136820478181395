import { useReactiveVar } from "@apollo/client";

import { hasAccessTokenVar } from "@/apollo/links/auth";
import ClientOnly from "@/components/hoc/ClientOnly";

interface LoggedOutOnlyProps {
  children: React.ReactNode;
}

function LoggedOutOnly({ children }: LoggedOutOnlyProps) {
  const isLogged = useReactiveVar(hasAccessTokenVar);
  return <ClientOnly>{!isLogged ? children : null}</ClientOnly>;
}

export default LoggedOutOnly;
